import React from 'react';
import BlogGrid from '../../components/blog/BlogGrid';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const blogTags = ["All", "Web Technology", "Innovation", "Software Engineering", "Tech News",
    "Programming", "React", "Ruby", "Ruby on Rails", "Design Principles", "AWS",
    "Microservices", "Golang", "Node.js", "Express", "TypeScript", "Angular", "Next.js"];

const Blogs = () => {
  return (
    <Layout>
      <PageMeta title="Welcome to fijalab Blogs" />
      <Navbar navDark />
      <PageHeader
        title="TechPulse: Fresh Tech and Web Insights"
        desc="Dive deep into the realm of web technologies with our specialized blog.
        Explore the latest in frontend tools like React, TypeScript, Angular, and Next.js,
         alongside backend frameworks including Node.js, Express, Ruby, and Golang.
         Delve into AWS cloud services and discover insights on modern design principles and
         architectural patterns such as microservices. Stay ahead of the curve with our curated content."
        blogTags={blogTags}
      />
      <BlogGrid />
      <FooterOne/>
    </Layout>
  );
};

export default Blogs;

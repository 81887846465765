const IconBoxData = [
  {
    id: 1,
    classOption: 'bg-primary',
    icon: 'fal fa-layer-group fa-2x text-white',
    title: 'Good Performance',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state .',
  },
  {
    id: 2,
    classOption: 'bg-danger',
    icon: 'fal fa-shield-check fa-2x text-white',
    title: 'Highly Secure',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state.',
  },
  {
    id: 3,
    classOption: 'bg-dark',
    icon: 'fal fa-code fa-2x text-white',
    title: 'Fast Development',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state.',
  },
];
const FaqOneData = [
  {
    id: 1,
    faqTitle: 'How does back pricing work?',
    faqDesc:
      'Progressively e-enable collaborative inexpensive supply chains. Efficiently maintain economically methods of empowerment for synergistic sound scenarios.',
  },
  {
    id: 2,
    faqTitle: 'How do I calculate how much price?',
    faqDesc:
      'Globally benchmark customized mindshare before clicks-and-mortar partnerships. Efficiently maintain economically sound scenarios and whereas client-based progressively.',
  },
  {
    id: 3,
    faqTitle: 'Can you show me an example?',
    faqDesc:
      'Dynamically visualize whereas competitive relationships. Progressively benchmark customized partnerships generate interdependent benefits rather sound scenarios and robust alignments.',
  },
];

const TestimonialData = [
  {
    id: 1,
    authorImg: '/assets/img/testimonial/profile_1.png',
    authorName: 'Mr.Rupan Oberoi',
    authorTitle: 'Founder and CEO at Amaara Herbs',
    quoteTitle: 'The Best Template You Got to Have it!',
    authorQuote:
      'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media than ethical',
  },
  {
    id: 2,
    authorImg: '/assets/img/testimonial/2.jpg',
    authorName: 'Joan Dho',
    authorTitle: 'Founder and CTO',
    quoteTitle: 'Best Template for SAAS Company!',
    authorQuote:
      'Dynamically create innovative core competencies with effective best practices promote innovative infrastructures.',
  },
  {
    id: 3,
    authorImg: '/assets/img/testimonial/3.jpg',
    authorName: 'Ranu Mondal',
    authorTitle: 'Lead Developer',
    quoteTitle: 'It is undeniably good!',
    authorQuote:
      'Rapidiously supply client-centric e-markets and maintainable processes progressively engineer',
  },
  {
    id: 4,
    authorImg: '/assets/img/testimonial/profile_2.png',
    authorName: 'Mr.Rupan Oberoi',
    authorTitle: 'Founder and CEO at Amaara Herbs',
    quoteTitle: 'The Best Template You Got to Have it!',
    authorQuote:
      'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media than ethical',
  },
];

const TestimonialOneData = [
  {
    id: '#testimonial-tab-1',
    activeClass: 'active',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: '/assets/img/testimonial/t-1.jpg',
    authorAvatar: '/assets/img/testimonial/profile_1.png',
  },
  {
    id: '#testimonial-tab-2',
    title: 'Embarrassed by the First Version.',
    desc: 'Energistically streamline robust architectures whereas distributed mindshare. Intrinsicly leveraother backend growth strategies through 24/365 products. Conveniently pursue revolutionary communities for compelling process improvements.',
    authorName: 'Rupan Oberoi',
    authorDesn: 'Web Designer',
    authorThumb: '/assets/img/testimonial/t-2.jpg',
    authorAvatar: '/assets/img/testimonial/2.jpg',
  },
  {
    id: '#testimonial-tab-3',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: '/assets/img/testimonial/t-3.jpg',
    authorAvatar: '/assets/img/testimonial/3.jpg',
  },
  {
    id: '#testimonial-tab-4',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: '/assets/img/testimonial/t-4.jpg',
    authorAvatar: '/assets/img/testimonial/profile_2.png',
  },
  {
    id: '#testimonial-tab-5',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: '/assets/img/testimonial/t-5.jpg',
    authorAvatar: '/assets/img/testimonial/5.jpg',
  },
];

const BlogData = [
  { 
    id: "3f2504e0-4f89-41d3-9a0c-0305e82c3301",
    title: "Navigating the Future: The Transformative Power of Data Science",
    generalDetails: "In the digital era, data has emerged as the new oil, powering the engines of innovation and driving the decision-making processes of organizations worldwide. Data science, a multidisciplinary field that uses scientific methods, processes, algorithms, and systems to extract knowledge and insights from structured and unstructured data, stands at the forefront of this revolution. It is transforming industries, reshaping businesses, and altering the way we understand the world around us.",
  },
  { id: "923f92d2-3f90-4f18-b108-fa9f2f0564c4" },
  { id: "5bb1e9e2-c3b0-4e19-af47-f2d62de9d6c2" },
  { id: "e42b0b55-bd7a-448d-8b79-4f03a7f2f0f8" },
  { id: "88d4334e-b8f8-4f0a-b9f8-8f9e60454768" },
  { id: "c0a29a69-5b8d-403b-9b15-3b9673dbf4a8" },
  { id: "f48c42f5-305d-4acc-832b-58cd9e9fa44c" },
  { id: "9a0f5d5a-f51a-439d-b5a9-fc48bf6d48a9" },
  { id: "7e914d0a-173e-4b8c-8c74-a1d3e045f115" },
  { id: "b34f5dc2-df34-46c8-92ff-ae164b5db396" },
  { id: "65d5f0c5-d05b-4aba-9b5a-54c0e2a0e1d3" },
  { id: "4d6e8cab-3b7e-4567-8aaf-12c8d4c9ced4" },
  { id: "faefe77e-93bf-4ad1-a9c3-4f9777bd9e8b" },
  { id: "0373da3d-7618-4c8b-916b-12eb2b3b758c" },
  { id: "e9e30bbe-1dca-45f6-85d8-756fa71e5281" },
  { id: "5c9b174e-c292-4706-9777-c76e8c44a2d4" },
  { id: "3e50a15f-e675-4b5d-8a68-831a5c8d5072" },
  { id: "af834a28-5872-4736-b4f5-f1b26a920938" },
  { id: "b7b1cd1d-1e44-457d-95ef-4ee52923c4d3" },
  { id: "8adf55e3-fc11-4987-97be-9a785a9d8b4a" }
];

export { IconBoxData, FaqOneData, TestimonialData, TestimonialOneData, BlogData };

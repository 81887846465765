import React from 'react';
import { Link } from 'react-router-dom';

const IntegrationThree = () => {
  return (
    <>
      <section className='our-integration ptb-120 bg-light'>
        <div className='container'>
          <div className='position-relative w-100'>
            <div className='row'>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-0 mt-lg-0 mt-md-0 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='/assets/img/integations/1.png'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Google Drive</h5>
                    <p className='mb-0 text-muted'>
                      Globally engage tactical niche markets rather than
                      client-based competently generate unique e-services
                    </p>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 mt-lg-0 mt-md-0 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='/assets/img/integations/2.png'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Google Drive</h5>
                    <p className='mb-0 text-muted'>
                      Globally engage tactical niche markets rather than
                      client-based competently generate unique e-services
                    </p>
                  </div>
                  <span className='badge position-absolute integration-badge bg-primary-soft px-3 py-2 text-primary'>
                    New
                  </span>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 mt-lg-0 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='/assets/img/integations/3.png'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Google Drive</h5>
                    <p className='mb-0 text-muted'>
                      Globally engage tactical niche markets rather than
                      client-based competently generate unique e-services
                    </p>
                  </div>
                  <span className='badge position-absolute integration-badge bg-danger-soft px-3 py-2 text-danger'>
                    Premium
                  </span>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='/assets/img/integations/4.png'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Google Drive</h5>
                    <p className='mb-0 text-muted'>
                      Globally engage tactical niche markets rather than
                      client-based competently generate unique e-services
                    </p>
                  </div>
                  <span className='badge position-absolute integration-badge bg-success-soft px-3 py-2 text-success'>
                    Free
                  </span>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='/assets/img/integations/5.png'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Google Drive</h5>
                    <p className='mb-0 text-muted'>
                      Globally engage tactical niche markets rather than
                      client-based competently generate unique e-services
                    </p>
                  </div>
                  <span className='badge position-absolute integration-badge bg-danger-soft px-3 py-2 text-danger'>
                    Premium
                  </span>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='/assets/img/integations/6.png'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Google Drive</h5>
                    <p className='mb-0 text-muted'>
                      Globally engage tactical niche markets rather than
                      client-based competently generate unique e-services
                    </p>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='/assets/img/integations/7.png'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Google Drive</h5>
                    <p className='mb-0 text-muted'>
                      Globally engage tactical niche markets rather than
                      client-based competently generate unique e-services
                    </p>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='/assets/img/integations/8.png'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Google Drive</h5>
                    <p className='mb-0 text-muted'>
                      Globally engage tactical niche markets rather than
                      client-based competently generate unique e-services
                    </p>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='/assets/img/integations/9.png'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Google Drive</h5>
                    <p className='mb-0 text-muted'>
                      Globally engage tactical niche markets rather than
                      client-based competently generate unique e-services
                    </p>
                  </div>
                  <span className='badge position-absolute integration-badge bg-warning-soft px-3 py-2 text-warning'>
                    Popular
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegrationThree;
